<template>
  <div class="manager-container">
    <div class="button-block">
      <div class="query-item" style="margin-left: 0px;">
        <span>IMO</span>
        <el-select placeholder="Select Barge/Vessel" v-model="filter.select" :style="{width:'200px'}">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"/>
        </el-select>
        <el-input placeholder="Search Barge/Vessel IMO" v-model="filter.imo" :style="{width:'200px', paddingLeft:'10px'}"></el-input>
      </div>
      <div class="query-item">
        <span>Date</span>
        <el-date-picker
          v-model="filter.date"
          type="datetimerange"
          size="mini"
          range-separator="to"
          start-placeholder="Start Date"
          end-placeholder="End Date"
        >
        </el-date-picker>
      </div>
      <div class="query-item">
        <el-button type="primary" size="mini" @click="query">{{ $t('query') }}</el-button>
        <el-button size="mini" @click="reset">{{ $t('reset') }}</el-button>
      </div>
    </div>
    <div class="table-block">
      <el-table 
        :data="tableData" v-loading="tableLoading" height="100%" :border="true" 
        :header-cell-style="HEADER_CELL_STYLE" :cell-style="CELL_STYLE" 
        stripe>
        <el-table-column label="Date Time" width="150px">
          <template v-slot="scope">
            <div>{{scope.row.createdAt ? $moment(scope.row.createdAt).format("YYYY-MM-DD HH:mm:ss") : ''}}</div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="Device" width="150">
          <template v-slot="scope">
            <div style="white-space: pre;">{{ scope.row.deviceId }}</div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="Job ID" width="200">
          <template v-slot="scope">
            <div style="white-space: pre;">{{ scope.row.jobId }}</div>
          </template>
        </el-table-column>
        <el-table-column label="Barge IMO" width="100">
          <template v-slot="scope">
            <div>{{ scope.row.ebdnJob ? scope.row.ebdnJob.bargeImo : '' }}</div>
          </template>
        </el-table-column>
        <el-table-column label="Vessel IMO" width="100">
          <template v-slot="scope">
            <div>{{ scope.row.ebdnJob? scope.row.ebdnJob.vesselImo : '' }}</div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="Event" min-width="200">
          <template v-slot="scope">
            <div style="white-space: pre;">{{ scope.row.event }}</div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="Message" min-width="200">
          <template v-slot="scope">
            <div style="white-space: pre;">{{ scope.row.message }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination
        v-if="tableData.length > 0"
        small
        @size-change="handlePageSizeChange"
        @current-change="handlePageChange"
        :page-size.sync="pagination.pageSize"
        :page-sizes="[10, 25, 50, 100]"
        :current-page="pagination.pageNumber"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { HEADER_CELL_STYLE, CELL_STYLE } from '@/constants.js'

export default {
  name: 'MobileAppLogging',
  data () {
    return {
      HEADER_CELL_STYLE, 
      CELL_STYLE,
      tableData: [],
      tableLoading: false,
      options: [
        { label: 'Barge', value: 'Barge' },
        { label: 'Vessel', value: 'Vessel' },
      ],
      filter: {
        select: '',
        imo: '',
        date: []
      },
      toggleIcon: false,
      pagination: {
        pageSize: 25,
        pageNumber: 1,
        total: 0
      }
    }
  },
  created() {
    this.getData()
  }, 
  methods: {
    getData() {
      this.tableLoading = true
      const select = this.filter.select ? this.filter.select : this.options[0].value
      const data = {
        pageSize: this.pagination.pageSize,
        pageNumber: this.pagination.pageNumber,
        bargeImo: this.filter.imo && select == this.options[0].value ? this.filter.imo : '',
        vesselImo: this.filter.imo && select == this.options[1].value ? this.filter.imo : '',
        createdAtStart: this.filter.date.length ? ( this.filter.date[0].toISOString() ?? null ) : null,
        createdAtEnd: this.filter.date.length ?  ( this.filter.date[1].toISOString() ?? null ) : null,
      }
      this.$request.post({
        url: this.$apis.queryMobileAppLog,
        data: data
      }).then( res => {
        if( res.code == 1000 ) {
          this.tableData = res.data?.content ?? []
          this.pagination.total = res.data?.totalElements ?? 0
        }
        this.tableLoading = false
      })
    },
    handlePageSizeChange( pageSize ) {
      this.pagination.pageSize = pageSize
      this.getData()
    },
    handlePageChange( pageNumber ) {
      this.pagination.pageNumber = pageNumber
      this.getData()
    },
    query() {
      this.pageNumber = 1
      this.getData()
    },
    reset() {
      this.filter = {
        select: '',
        imo: '',
        date: []
      }
      this.query()
    }
  }
}
</script>
<style lang="scss" scoped>
.manager-container {
  height: 100%;
  width: 100%;

  .table-block {
    height: calc(100% - 114px);
    background-color: blue
  }

  .button-block {
    @include flex-center-row;
    justify-content: left;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 10px;

    /deep/ .el-input__inner {
      border-color: #eaecef;
    }

    .query-item {
      display: inline-flex;
      align-items: center;
      margin: 10px 20px;

      span {
        display: inline-block;
        margin-right: 10px;
        flex: 5em;
      }
    }
  }
}
</style>